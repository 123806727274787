import api from "@/services/api";

export default {
  async setApplications({ commit }, data) {
    commit("setApplications", data);
  },
  resetApplicationCache({ commit, state }) {
    commit("setApplicationId", undefined);
    commit("setApplication", undefined);
    commit("setSaveFilledForm", state.getDefaultContent());
  },

  async submitProcess({ commit, dispatch }, { id, adminMode }) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/customer/process/${id}/submit`)
        .then(() => {
          commit("setApplicationId", undefined);
          commit("setApplication", undefined);
          commit("isLoading", false);
          if (adminMode) {
            dispatch("application/getManagerApplications", "", { root: true });
          } else {
            dispatch("application/getApplications", "", { root: true });
            dispatch("application/getSavedApplications", "", { root: true });
          }
          dispatch("user/refreshLicenses", "", { root: true });
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async removeProcess({ commit, dispatch }, id) {
    commit("isLoadingDelete");
    return new Promise((resolve, reject) => {
      return api
        .delete(`/application/${id}`)
        .then(() => {
          commit("isLoadingDelete", false);
          dispatch("application/getApplications", "", { root: true });
          dispatch("application/getSavedApplications", "", { root: true });
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async createApplication({ commit, state, dispatch }, data) {
    commit("isLoadingSubmit", true);
    const isCreating = !state.applicationId;

    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/application/${!isCreating ? state.applicationId : ""}`,
          method: isCreating ? "POST" : "PATCH",
          data,
        })
        .then(({ data }) => {
          commit("isLoadingSubmit", false);
          if (isCreating) {
            commit("setApplicationId", data.id);
            commit("setApplication", data);
          }
          dispatch("application/getApplications", "", { root: true });
          dispatch("application/getSavedApplications", "", { root: true });
          resolve(data);
        })
        .catch((error) => {
          commit("isLoadingSubmit", false);
          reject({ status: false, error });
        });
    });
  },

  async getApplications({ commit }, params = {}) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get("/application", { params })
        .then((result) => {
          commit("isLoading", false);
          commit("setApplications", result.data.items);
          commit("setPagination", result.data.meta);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },

  async getManagerApplications({ commit }, params = {}) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get("/manager/applications", { params })
        .then((result) => {
          commit("isLoading", false);
          commit("setApplications", result.data.items);
          commit("setPagination", result.data.meta);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },

  async getSavedApplications({ commit }, data = {}) {
    commit("isSavedLoading");
    return new Promise((resolve, reject) => {
      return api
        .get("/application/saved", { params: data })
        .then((result) => {
          commit("isSavedLoading", false);
          commit("setSavedApplications", result.data.items);
          commit("setSavedPagination", result.data.meta);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async getApplication({ commit }, id) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/application/${id}`)
        .then((result) => {
          commit("isLoading", false);
          commit("setSaveFilledForm", result.data.content);
          commit("setApplication", result.data);
          commit("setApplicationId", id);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async getConfirmation({ commit }, id) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/application/${id}/confirmation`)
        .then((result) => {
          commit("isLoading", false);
          resolve(result.data.file);
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },

  async cloneApplication({ commit, dispatch }, id) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/application/${id}/clone`)
        .then((result) => {
          commit("isLoading", false);
          dispatch("application/getManagerApplications", "", { root: true });
          resolve(result.data);
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async getResume({ commit }, id) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/application/${id}/resume`)
        .then((result) => {
          commit("isLoading", false);
          resolve(result.data.file);
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },

  async uploadPhoto({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/application/${state.applicationId}/upload`,
          method: "POST",
          data,
        })
        .then(() => {
          commit("isLoadingPhoto", false);
          resolve({ status: true });
        })
        .catch((error) => {
          commit("isLoadingPhoto", false);
          reject({ status: false, error });
        });
    });
  },

  async updateStatus({ commit, dispatch }, { id, status }) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      api
        .put(`/application/${id}/status`, { status })
        .then(() => {
          commit("isLoading", false);
          dispatch("application/getApplications", "", { root: true });
          resolve({ status: true });
        })
        .catch((error) => {
          commit("isLoading", false);
          reject({ status: false, error });
        });
    });
  },
};
